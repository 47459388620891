import React, { useState, useRef } from 'react'
import '../styles/MainContainer.css';
import { useContextMenu } from '../context/ContextMenuContext';
import ContextMenu from '../ContextMenu/ContextMenu';
import ContextMenuItem from '../ContextMenu/ContextMenuItem';
import StatusDot from '../status/StatusDot';
import './EventList.css';
import '../popup/EditPopup.css';
import ListHeader from '../list/ListHeader';
import ListItem from '../list/ListItem';
import Checkbox from '../input/Checkbox';
import api from '../wrapper/API';
import { useSuccessBar } from '../context/SuccessBarContext';
import Popup from '../popup/Popup';
import { usePopup } from '../context/PopupContext';

function EventList({posts, setPosts, eventId, onListItemClick}) {
  const {startSuccessBarLoading, startSuccessBarSuccess, closeSuccessBar} = useSuccessBar();
  const {openPopup, popupData} = usePopup();
  const [error, setError] = useState("");

  //Checkbox Anwesend
  const handleListItemClick = (event, playerId) => {
    if (localStorage.getItem("statusId") === "4") {
      openPopup("warningdeleteeventplayer", {playerId: playerId, event: event});
    } else {
      changePlayerPresence(event, playerId);
    }
  };

  const handleChangePlayerPresence = () => {
    changePlayerPresence(popupData.identifier.event, popupData.identifier.playerId);
  }

  const changePlayerPresence = (event, playerId) => {
    const foundPost = posts.find(post => post.id === playerId);
    if (onListItemClick) {
      onListItemClick(event, playerId, foundPost.lastname, foundPost.firstname);
    }
    if (event.defaultPrevented) return;
    const newState = foundPost.present === 2 ? 1 : foundPost.present + 1;
    const requestBody = {
      playerId: playerId,
      eventId: eventId,
      present: newState
    }

    api.put(`/EventPlayer/UpdatePresent?playerId=${requestBody.playerId}&eventId=${requestBody.eventId}&present=${requestBody.present}`)
      .then(() => {
        const updatedPosts = [...posts];
        const postIndex = updatedPosts.findIndex(post => post.id === foundPost.id);
        updatedPosts[postIndex].present = requestBody.present;
        setPosts(updatedPosts);
      })
      .catch(error => {
        setError(error.response.data);
        openPopup("eventplayererror", playerId);
      })
  };

  //Confirm Delete Popup
  const handleConfirmDelete = () => {
    startSuccessBarLoading("Spieler wird entfernt");
    const foundPost = posts.find(post => post.id === contextMenuData.identifier);
    api.delete(`/EventPlayer/DeleteParticipant?playerId=${foundPost.id}&eventId=${eventId}`)
      .then(() => {
        startSuccessBarSuccess("Speiler entfernt")
        const updatedPosts = posts.filter(post => post.id !== foundPost.id);
        setPosts(updatedPosts);
      })
      .catch(error => {
        closeSuccessBar();
        setError(error.response.data);
        openPopup("eventplayererror", foundPost.id);
      })
  };

  //Info Popup
  const [info, setInfo] = useState({
    lastname: '',
    firstname: '',
    statusId: 0,
    equipment: 0,
    present: 0,
    phone: '',
    email: '',
    memo: '',
    priceCents: null
  });

  const showPlayerInfo = () => {
    const foundPost = posts.find(post => post.id === contextMenuData.identifier);
    setInfo(foundPost);
    openPopup('eventplayer', contextMenuData.identifier);
  };

  //Edit Popup
  const memoInputRef = useRef();
  const priceInputRef = useRef();

  const handleEditEventPlayerConfirm = () => {
    startSuccessBarLoading("Änderungen werden übernommen");
    const requestBody = {
      playerId: info.id,
      eventId: eventId,
      memo: memoInputRef.current.value,
      priceCents: priceInputRef.current.value
    }

    let requestLink = `/EventPlayer/Edit?playerId=${requestBody.playerId}&eventId=${eventId}`;
    if (requestBody.memo !== '') requestLink += `&memo=${requestBody.memo}`;
    if (requestBody.priceCents !== '') requestLink += `&priceCents=${requestBody.priceCents * 100}`;

    api.put(requestLink)
      .then((response) => {
        startSuccessBarSuccess("Änderungen übernommen");
        const updatedPosts = [...posts];
        const postIndex = updatedPosts.findIndex(post => post.id === info.id);
        updatedPosts[postIndex].memo = response.data.memo;
        updatedPosts[postIndex].priceCents = response.data.priceCents;
        setPosts(updatedPosts);
      })
      .catch(error => {
        closeSuccessBar();
        setError(error.response.data);
        openPopup("eventplayererror", requestBody.playerId);
      })
    
  };

  const updatePriceCents = (event) => {
    const newValue = event.target.value;
    setInfo((prevInfo) => ({
      ...prevInfo,
      priceCents: newValue === '' ? null : newValue * 100
    }));
  };

  //Context menu
  const { contextMenuData } = useContextMenu();

  function callPlayer() {
    const foundPost = posts.find(post => post.id === contextMenuData.identifier);
    window.location.href = `tel:${foundPost.phone}`;
  }

  function emailPlayer() {
    const foundPost = posts.find(post => post.id === contextMenuData.identifier);
    window.location.href = `mailto:${foundPost.email}`;
  }

  function editPlayer() {
    const foundPost = posts.find(post => post.id === contextMenuData.identifier);
    setInfo(x => x = foundPost);
    openPopup('editeventplayer', contextMenuData.identifier);
  }

  function resetPlayerPresence() {
    const foundPost = posts.find(post => post.id === contextMenuData.identifier);
    const requestBody = {
      playerId: foundPost.id,
      eventId: eventId,
      present: 0
    }
    api.put(`/EventPlayer/UpdatePresent?playerId=${requestBody.playerId}&eventId=${requestBody.eventId}&present=${requestBody.present}`)
      .then(() => {
        const updatedPosts = [...posts];
        const postIndex = updatedPosts.findIndex(post => post.id === foundPost.id);
        updatedPosts[postIndex].present = requestBody.present;
        setPosts(updatedPosts);
      })
      .catch(error => {
        setError(error.response.data);
        openPopup("eventplayererror", requestBody.playerId);
      })
  };

  function toggleEquipment() {
    const foundPost = posts.find(post => post.id === contextMenuData.identifier);
    const requestBody = {
      playerId: foundPost.id,
      eventId: eventId,
      equipment: foundPost.equipment ? 0 : 1
    }

    api.put(`/EventPlayer/UpdateNeedsEquipment?eventId=${requestBody.eventId}&playerId=${requestBody.playerId}&brauchtLeihausruestung=${requestBody.equipment}`)
      .then((response) => {
        const updatedPosts = [...posts];
        const postIndex = updatedPosts.findIndex(post => post.id === foundPost.id);
        updatedPosts[postIndex].equipment = response.data.equipment;
        updatedPosts[postIndex].priceCents = response.data.priceCents;
        setPosts(updatedPosts);
      })
      .catch(error => {
        setError(error.response.data);
        openPopup("eventplayererror", requestBody.playerId);
      })
  }

  function removeParticipant() {
    openPopup("confirmdeleteeventplayer", "");
  }

  return (
    <React.Fragment>
      <main className={posts.lenth > 0 ? "extendedMain hasPosts" : "extendedMain"}>
        <ListHeader gridTemplateColumns={'repeat(6, 1fr)'}>
          <p>Mem</p>
          <p>Nach</p>
          <p>Vor</p>
          <p>Leih</p>
          <p>Zahlen</p>
          <p>Da</p>
        </ListHeader>
        {posts.map((post, index) => (
          <ListItem 
            key={post.id}
            identifier={post.id}
            gridTemplateColumns={'repeat(6, 1fr)'}
            memo={post.memo}
            contextMenuIdentifier='eventplayer'
            index={index}
            onClick={handleListItemClick}
          >
            <StatusDot type='member' statusId={post.statusId} />
            <p>{post.lastname}</p>
            <p>{post.firstname}</p>
            <StatusDot type='equipment' statusId={post.equipment} />
            <p>{post.priceCents === 0 ? "" : (post.priceCents / 100).toFixed(2) + '€'}</p>
            <Checkbox className={"playerPresent"} identifier={post.id} selectedState={post.present} />
          </ListItem>
        ))}
      </main>
      <ContextMenu type={'eventplayer'}>
        <ContextMenuItem label={'Anrufen'} icon={'call'} type={2} onClick={callPlayer} />
        <ContextMenuItem label={'E-Mail'} icon={'mail'} type={3} onClick={emailPlayer} />
        <ContextMenuItem label={'Info'} icon={'question_mark'} type={0} onClick={showPlayerInfo} />
        <ContextMenuItem label={'Bearbeiten'} icon={'edit'} type={0} onClick={editPlayer} />
        <ContextMenuItem label={'Zurücksetzen'} icon={'history'} type={0} onClick={resetPlayerPresence} />
        <ContextMenuItem label={'Leihausrüstung'} icon={'star'} type={0} onClick={toggleEquipment} />
        <ContextMenuItem label={'Entfernen'} icon={'delete_sweep'} type={1} onClick={removeParticipant} />
      </ContextMenu>
      <Popup name='confirmdeleteeventplayer' confirmText='Entfernen' headerText='Aktion bestätigen' 
        type="confirmaction" onConfirm={handleConfirmDelete} withCancel
      >
        <p>Bist du sicher, dass du den Spieler von diesem Event entfernen willt?</p>
      </Popup>
      <Popup name='warningdeleteeventplayer' confirmText='Bestätigen' headerText='Warnung'
        type="warning" onConfirm={handleChangePlayerPresence} withCancel
      >
        <p>Der Admin Account ist nicht dafür vorgesehen, die Anwesenheit eines Spielers zu Ändern. Möchtest du dennoch fortfahren?</p>
      </Popup>
      <Popup name='eventplayer' confirmText='Ok' headerText='Info' type="info">
        <h3>Memo:</h3>
        <div className={"memoOutput"}>
          {info.memo.split('\\n').map((zeile, index) => (
          index % 2 === 0 ? <h4 key={index}>{zeile}</h4> : <p key={index}>{zeile}</p>
        ))}</div>
        <h3>Angepasster Preis:</h3>
        <p>{info.priceCents === null ? 'Standard' : `${info.priceCents / 100}€` }</p>
      </Popup>
      <Popup name='editeventplayer' confirmText={'Übernehmen'} onConfirm={handleEditEventPlayerConfirm} withCancel type="info">
        <h3>Memo:</h3>
        <textarea ref={memoInputRef} className={"memoInput"} maxLength={200} placeholder='Memoeintrag hinzufügen'></textarea>
        <h3>Preis:</h3>
        <input type='number' ref={priceInputRef} className={"priceInput"} min={0} max={100} step={0.5} placeholder='Standard' value={info.priceCents === null ? "" : info.priceCents / 100} onChange={updatePriceCents} />
      </Popup>
      <Popup name='eventplayererror' confirmText='Ok' headerText='Error' type="error">
        <p>{error}</p>
      </Popup>
    </React.Fragment>
  )
}

export default EventList