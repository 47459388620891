import React, { useState } from 'react';
import { useContextMenu } from '../context/ContextMenuContext';
import './List.css';
import Icon from '../home/Icon';
import { motion } from 'framer-motion';


function ListItem({children, contextMenuIdentifier = '', identifier = 0, gridTemplateColumns = '1fr', memo = '', index = 0, onClick}) {
  //Context Menu
  const { openContextMenu } = useContextMenu();

  const handleContextMenu = (event) => {
    openContextMenu(event, identifier, contextMenuIdentifier);
  };

  const handleListItemClick = (event) => {
    if (onClick) {
      onClick(event, identifier);
    }
  }

  const listItemVariants = {
    initial: {
      opacity: 0,
      scale: 1
    },
    visible: {
      opacity: 1,
      transition: {
        delay: 0.06 * index,
        duration: 0.4
      }
    },
    hover: {
      scale: 1.02,
      cursor: "pointer"
    },
    click: {
      scale: 0.98,
      transition: {
        duration: 0.001
      }
    }
  }

  const [longPressTimer, setLongPressTimer] = useState(null);

  const handleTouchMove = () => {
    if (longPressTimer !== null) {
      clearTimeout(longPressTimer);
    }
  };

  const handleTouchStart = (event) => {
    setLongPressTimer(setTimeout(() => {
      handleContextMenu(event);
    }, 150));
  }

  const handleTouchEnd = (event) => {
    if (longPressTimer !== null) {
      clearTimeout(longPressTimer);
    }
  }

  return (
    <motion.div 
      className={"listContainer"} 
      onContextMenu={handleContextMenu}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      style={{gridTemplateColumns: `${gridTemplateColumns}`}}
      onClick={handleListItemClick}
      variants={listItemVariants}
      initial="initial"
      animate="visible"
      whileHover="hover"
      whileTap="click"
    >
      {memo !== '' ? <Icon className={"memoIcon"} icon='note_stack' /> : ''}
      {children}
    </motion.div >
  )
}

export default ListItem