import React from 'react'
import './StatusDot.css'

function StatusDot({type = 'member', statusId = 0, style = {}, className = ''}) {
  let output = '';

  if (type === 'member') {
    output = <div id={statusId} className={`statusDot member ${className}`} style={style}></div>
  } else if (type=== 'event') {
    output = <div id={statusId} className={`statusDot event ${className}`} style={style}></div>
  } else {
    output = <div id={statusId} className={`statusDot equipment ${className}`} style={style}></div>
  }

  return (
    output
  )
}

export default StatusDot