import React from 'react';
import './Form.css';
import { motion } from 'framer-motion';
import Icon from '../home/Icon';

function SubmitButton({icon = 'person_add'}) {
  const buttonVariants = {
    initial: {
      scale: 1
    },
    hover: {
      scale: 1.07,
      cursor: "pointer"
    },
    click: {
      scale: 0.90,
      transition: {
        duration: 0.01
      }
    }
  }

  return (
    <motion.button 
      type='submit' 
      className={"addNew"}
      variants={buttonVariants}
      initial="initial"
      whileHover="hover"
      whileTap="click"
    >
      <Icon className={"addNewIcon"} icon={icon} />
    </motion.button>
  )
}

export default SubmitButton