import React from 'react'
import './ContextMenu.css';
import Icon from '../home/Icon';
import { useContextMenu } from '../context/ContextMenuContext';

function ContextMenuItem({ icon, label, type, onClick }) {
  const { closeContextMenu } = useContextMenu();

  const handleMenuItemClick = () => {
    closeContextMenu();
    onClick();
  };

  return (
    <li className={`contextMenuItem ${
        type === 1
        ? "negative"
        : type === 2
        ? "positive"
        : type === 3
        ? "accent"
        : ''
      }`} 
      onClick={handleMenuItemClick}>
      <Icon className={"contextMenuIcon"} icon={icon} />
      <p className={"contextMenuText"}>{label}</p>
    </li>
  )
}

export default ContextMenuItem