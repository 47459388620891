import React, { useRef, useState } from 'react'
import '../styles/MainContainer.css'
import { Link } from 'react-router-dom';
import { useContextMenu } from '../context/ContextMenuContext';
import ContextMenu from '../ContextMenu/ContextMenu';
import ContextMenuItem from '../ContextMenu/ContextMenuItem';
import StatusDot from '../status/StatusDot';
import './UpcomingEventsList.css';
import '../popup/EditPopup.css'
import ListHeader from '../list/ListHeader';
import ListItem from '../list/ListItem';
import api from '../wrapper/API';
import { useSuccessBar } from '../context/SuccessBarContext';
import { useTour } from '@reactour/tour';
import Analytics from '../analytics/Analytics';
import Popup from '../popup/Popup';
import { usePopup } from '../context/PopupContext';

function UpcomingEventsList({posts, setPosts}) {
  const {startSuccessBarLoading, startSuccessBarSuccess, closeSuccessBar} = useSuccessBar();
  const [error, setError] = useState("");
  const {openPopup, closePopup, popupData} = usePopup();

  //Confirm Delete Popup
  const handleConfirmDelete = () => {
    startSuccessBarLoading("Event wird abgesagt");
    const foundPostIndex = posts.findIndex(post => post.id === contextMenuData.identifier);
  
    api
      .put(`/Event/CancleEvent?eventId=${contextMenuData.identifier}`)
      .then(() => {
        startSuccessBarSuccess("Event abgesagt");
        const updatedPosts = [...posts];
        updatedPosts[foundPostIndex].statusId = 4;
        setPosts(updatedPosts);
      })
      .catch((error) => {
        closeSuccessBar();
        setError(error.response.data);
        openPopup("eventerror", posts[foundPostIndex].id);
      });
  
    closePopup();
  };

  const handleOpenPopup = () => {
    openPopup("confirmdeleteevent", contextMenuData.identifier);
  };

  //Info Popup
  const [info, setInfo] = useState({
    memo: '',
    date: '',
    statusId: 0
  });

  const showEventInfo = () => {
    const foundPost = posts.find(post => post.id === contextMenuData.identifier);
    setInfo(foundPost);
    openPopup('event', foundPost.id);
  };

  //Edit Popup
  const memoInputRef = useRef();

  const handleEditEventConfirm = () => {
    startSuccessBarLoading("Änderungen werden übernommen");
    const foundPost = posts.find(post => post.id === popupData.identifier);

    const requestBody = {
      eventId: foundPost.id,
      memo: memoInputRef.current.value
    }

    api.put(`/Event/AddMemo?eventId=${requestBody.eventId}&memo=${requestBody.memo}`)
      .then((response) => {
        startSuccessBarSuccess("Änderungen übernommen");
        const updatedPosts = [...posts];
        const postIndex = updatedPosts.findIndex(post => post.id === foundPost.id);
        updatedPosts[postIndex].memo = response.data.memo;
        setPosts(updatedPosts);
      })
      .catch(error => {
        closeSuccessBar();
        setError(error.response.data);
        openPopup("eventerror", requestBody.eventId);
      })
  };

  //ContextMenu
  const { contextMenuData } = useContextMenu();

  function editEvent() {
    openPopup('editevent', contextMenuData.identifier);
  }

  function showAnalytics() {
    startSuccessBarLoading("Statistiken werden geladen");
    api.get(`/Event/GetEventStats?eventId=${contextMenuData.identifier}`)
      .then(response => {
        const stats = response.data;
        setCards( 
          [
            {
              title: "Einnahmen", 
              icon: "attach_money", 
              stats: [
                {
                  title: "Gewinn",
                  value: (stats.profitTotal / 100).toFixed(2),
                  max: (stats.profitEstimated / 100).toFixed(2),
                  negative: false,
                  suffix: "€"
                },
                {
                  title: "Lohn",
                  value: (stats.lossSalary / 100).toFixed(2),
                  max: (stats.profitEstimated / 100).toFixed(2),
                  negative: true,
                  suffix: "€"
                },
                {
                  title: "Abwesenheit",
                  value: (stats.lossNotPresent / 100).toFixed(2),
                  max: (stats.profitEstimated / 100).toFixed(2),
                  negative: true,
                  suffix: "€"
                }
              ]
            },
            {
              title: "Anwesenheit", 
              icon: "person", 
              stats: [
                {
                  title: "Anwesend",
                  value: stats.presenceTotal,
                  max: stats.presenceEstimated,
                  negative: false,
                  suffix: ""
                }
              ]
            },
            {
              title: "Anteil", 
              icon: "person", 
              stats: [
                {
                  title: "Orga",
                  value: stats.countOrga,
                  max: stats.presenceEstimated,
                  negative: false,
                  suffix: ""
                },
                {
                  title: "Member",
                  value: stats.countMember,
                  max: stats.presenceEstimated,
                  negative: false,
                  suffix: ""
                },
                {
                  title: "Spieler",
                  value: stats.countPlayer,
                  max: stats.presenceEstimated,
                  negative: false,
                  suffix: ""
                }
              ]
            }
          ]
        )
        closeSuccessBar();
        openPopup("eventanalytics", contextMenuData.identifier);
      })
      .catch(() => {
        closeSuccessBar();
      });
  }

  const cancelEvent = () => {
    handleOpenPopup();
  };

  const activateEvent = () => {
    startSuccessBarLoading("Event wird aktiviert");
    const foundPostIndex = posts.findIndex(post => post.id === contextMenuData.identifier);
  
    api
      .put(`/Event/ActivateEvent?eventId=${contextMenuData.identifier}`)
      .then((response) => {
        startSuccessBarSuccess("Event aktiviert");
        const updatedPosts = [...posts];
        updatedPosts[foundPostIndex].statusId = response.data.statusId;
        setPosts(updatedPosts);
      })
      .catch((error) => {
        closeSuccessBar();
        setError(error.response.data);
        openPopup("eventerror", posts[foundPostIndex].id);
      });
  }

  const {isOpen, setIsOpen} = useTour();

  const handleOnboarding = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  }

  //Analytics
  const [cards, setCards] = useState([
    {
      title: "Einnahmen", 
      icon: "attach_money", 
      stats: [
        {}
      ]
    },
    {
      title: "Anwesenheit", 
      icon: "person", 
      stats: [
        {}
      ]
    }
  ]);

  return (
    <React.Fragment>
      <main className={"extendedMain"}>
        <ListHeader gridTemplateColumns={'1fr 1fr 1fr 1fr'}>
          <p>Datum</p>
          <p>Teilnehmer</p>
          <p>Leih</p>
          <p>Status</p>
        </ListHeader>
        {posts.map((post, index) => (
            <Link to={`/event/${post.id}`} key={post.id} onClick={handleOnboarding}>
              <ListItem 
                key={post.id}
                identifier={post.id}
                gridTemplateColumns={'1fr 1fr 1fr 1fr'}
                memo={post.memo}
                contextMenuIdentifier='upcomingevents'
                index={index}
              >
                <p>{`${new Date(post.date).getDate()}.${new Date(post.date).getMonth() + 1}.${new Date(post.date).getFullYear()}`}</p>
                <p>{post.participants}</p>
                <p>{post.equipment}</p>
                <StatusDot type='event' statusId={post.statusId} />
              </ListItem>
            </Link>
          ))
        }
      </main>
      <ContextMenu type={'upcomingevents'}>
        <ContextMenuItem label={'Info'} icon={'question_mark'} type={0} onClick={showEventInfo} />
        <ContextMenuItem label={'Bearbeiten'} icon={'edit'} type={0} onClick={editEvent} />
        <ContextMenuItem label={'Statistiken'} icon={'show_chart'} type={0} onClick={showAnalytics} />
        {posts.find(post => post.id === contextMenuData.identifier)?.statusId === 4 ? (
          <ContextMenuItem label={'Aktivieren'} icon={'event_available'} type={2} onClick={activateEvent} />
        ) : (
          <ContextMenuItem label={'Absagen'} icon={'event_busy'} type={1} onClick={cancelEvent} />
        )}
      </ContextMenu>
      <Popup name='confirmdeleteevent' confirmText='Entfernen' headerText='Aktion bestätigen' 
        type="confirmaction" onConfirm={handleConfirmDelete} withCancel
      >
        <p>Bist du sicher, dass du das Event absagen möchtest?</p>
      </Popup>
      <Popup name='event' headerText='Infos' type="info">
        <h3>Memo:</h3>
        <div className={"memoOutput"}>
          {info.memo.split('\\n').map((zeile, index) => (
          index % 2 === 0 ? <h4 key={index}>{zeile}</h4> : <p key={index}>{zeile}</p>
        ))}</div>
        <h3>Datum:</h3>
        <p>{info.date}</p>
        <h3>Status:</h3>
        <StatusDot type='event' statusId={info.statusId} style={{alignSelf: 'center', justifySelf: 'center'}} />
      </Popup>
      <Popup name='editevent' confirmText={'Hinzufügen'} onConfirm={handleEditEventConfirm} withCancel type="info">
        <h3>Memo:</h3>
        <textarea required ref={memoInputRef} className={"memoInput"} maxLength={200} placeholder='Memoeintrag hinzufügen'></textarea>
      </Popup>
      <Popup name='eventanalytics' confirmText={'Ok'} type="info">
        <Analytics cards={cards} />
      </Popup>
      <Popup name='eventerror' confirmText='Ok' headerText='Error' type="error">
        <p>{error}</p>
      </Popup>
    </React.Fragment>
  )
}

export default UpcomingEventsList