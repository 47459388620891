import React, { useState, useContext, useCallback } from 'react';

// Erstelle den Kontext
const InfoPopupContext = React.createContext();

// Hook zur Verwendung des Kontextes in Komponenten
export const useInfoPopup = () => {
  return useContext(InfoPopupContext);
};

// Kontextanbieter-Komponente
export const InfoPopupProvider = ({ children }) => {
  const [isInfoPopupOpen, setInfoPopupOpen] = useState(false);
  const [infoPopupData, setInfoPopupData] = useState({ type: '', identifier: '' });

  // Funktion zum Öffnen des Kontextmenüs
  const openInfoPopup = useCallback((type, identifier) => {
    setInfoPopupData({ type, identifier });
    setInfoPopupOpen(true);
  }, []);

  // Funktion zum Schließen des Kontextmenüs
  const closeInfoPopup = useCallback(() => {
    setInfoPopupOpen(false);
  }, []);

  return (
    <InfoPopupContext.Provider value={{ isInfoPopupOpen, infoPopupData, openInfoPopup, closeInfoPopup }}>
      {children}
    </InfoPopupContext.Provider>
  );
};