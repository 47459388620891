import React from 'react';
import './CustomComboBox.css';

function CustomComboBox({ children, placeholder, selected = 0, onStatusChange }) {

  const handleCellClick = (index) => {
    if (onStatusChange) {
      onStatusChange(index);
    }
  };

  const gridItems = React.Children.map(children, (child, index) => {
    const isSelected = index === selected;

    return (
      <div className={`customComboBoxItemContainer  ${isSelected ? "selected" : "deselected"}`} onClick={() => handleCellClick(index)}>
        <div className={"customComboBoxItem"}>
          {child}
        </div>
      </div>
    );
  });

  return (
    <div className={"customComboBoxContainer"}>
      {placeholder ? <p>{placeholder}:</p> : ''}
      {gridItems}
    </div>
  );
}

export default CustomComboBox;