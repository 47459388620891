import React from 'react';
import './RulesAndPricelist.css';
import Icon from './Icon';
import { Link } from 'react-router-dom';

export default function RulesAndPricelist() {
  return (
    <div className={"rulesPricesContainer"}>
      <Link to={`/rules`}>
        <div className={"rulesContainer"}>
          <Icon className={"rulesPricesIcon"} icon='gavel' />
          <p className={"rulesPricesText"}>Regelwerk</p>
        </div>
      </Link>
      <Link to={`/prices`}>
        <div className={"pricesContainer"}>
          <Icon className={"rulesPricesIcon"} icon='sell' />
          <p className={"rulesPricesText"}>Preisliste</p>
        </div>
      </Link>
    </div>
  )
}
