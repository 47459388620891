import React, { useState } from 'react';
import { DarkModeProvider } from './components/context/DarkModeContext';
import { ErrorPopupProvider } from './components/context/ErrorPopupContext';
import Wrapper from './components/wrapper/Wrapper';
import { InfoPopupProvider } from './components/context/InfoPopupContext';
import { AuthProvider } from './components/context/AuthContext';
import { SuccessBarProvider } from './components/context/SuccessBarContext';
import { TourProvider } from '@reactour/tour';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import api from './components/wrapper/API';
import { PopupProvider } from './components/context/PopupContext';

function App() {
  const steps = [
    {
      content: "Willkommen bei der Orga! Lass mich dir kurz erklären, wie du über diese Website die Anwesenheiten richtig managen kannst.",
      position: "center",
      action: () => {
        if (route.pathname !== "/") {
          redirect("/", true);
        }
      }
    },
    {
      selector: ".navCenterSection",
      content: "Hier kannst du zu den verschiedenen Teilen der Website navigieren. Momentan befindest du dich auf der Startseite.",
      action: () => {
        if (route.pathname !== "/") {
          redirect("/", true);
        }
      }
    },
    {
      selector: ".nextEventContainer",
      content: "Das ist der nächste anstehende Spieltag. Durch einen Klick kommst du direkt zu der Anwesenheitsliste. Probiers aus!",
      position: "bottom",
      action: () => {
        setAllowNavigation(false);
        if (route.pathname !== "/") {
          redirect("/", true);
        }
      }
    },
    {
      selector: ".navEventIcon",
      content: "Hier siehst du eine Auflistung aller Teilnehmer.",
      action: () => {
        if (!route.pathname.includes("/event")) {
          redirect(`/event/${searchParams.get("eventId")}`, true);
        }
      }
    },
    {
      selector: ".extendedMain",
      content: "Durch einen Klick auf einen Teilnehmer kannst du ihn als \"Anwesend\" markieren. Klicke nochmal, um ihn als \"Fehlend\" zu kennzeichnen.",
      action: () => {
        if (!route.pathname.includes("/event")) {
          redirect(`/event/${searchParams.get("eventId")}`, true);
        }
      }
    },
    {
      selector: ".extendedMain",
      content: "Durch einen Rechtsklick auf einen Teilnehmer kannst du zusätzlich informationen über den Spieler anzeigen lassen oder ändern.",
      action: () => {
        if (!route.pathname.includes("/event")) {
          redirect(`/event/${searchParams.get("eventId")}`, true);
        }
      }
    },
    {
      selector: window.innerWidth < 1000 ? ".formControlsIcon" : ".formContainer",
      content: "Wenn du einen Spieler zu diesem Spieltag hinzufügen möchtest, kannst du das hier machen. Wenn der Spieler schon mal da war, reicht sogar nur der Vor- und Nachname. Wenn du eine Email und Telefonnummer einträgst, wird der Spieler automatisch erstellt und hinzugefügt.",
      position: "top",
      action: () => {
        if (!route.pathname.includes("/event")) {
          redirect(`/event/${searchParams.get("eventId")}`, true);
        }
      }
    },
    {
      selector: ".navUsersIcon",
      content: "Diese Liste zeigt dir alle zur Zeit eingetragenen Spieler an.",
      action: () => {
        if (route.pathname !== "/users") {
          redirect("/Users", true);
        }
      }
    },
    {
      selector: ".extendedMain",
      content: "Durch einen Rechtsklick auf einen Spieler kannst du dir unter anderem zusätzliche Infos wie die Memo (Eine Kurznotiz zu dem Spieler) anzeigen lassen.",
      action: () => {
        if (route.pathname !== "/users") {
          redirect("/Users", true);
        }
      }
    },
    {
      selector: window.innerWidth < 1000 ? ".formControlsIcon" : ".formContainer",
      content: "Außerdem kannst du hier die Spieler manuell erstellen, ohne diesen automatisch zu einem Spieltag hinzuzufügen.",
      position: "top",
      action: () => {
        if (route.pathname !== "/users") {
          redirect("/Users", true);
        }
      }
    },
    {
      selector: ".navEventIcon",
      content: "Und last but not least findest du hier alle eingetragenen Spieltage.",
      action: () => {
        if (route.pathname !== "/upcoming-events") {
          redirect("/Upcoming-Events", true);
        }
      }
    },
    {
      selector: ".extendedMain",
      content: "Durch einen Rechtsklick kannst du zum Beispiel einen Spieltag absagen oder wieder aktivieren.",
      action: () => {
        if (route.pathname !== "/upcoming-events") {
          redirect("/Upcoming-Events", true);
        }
      }
    },
    {
      selector: ".extendedMain",
      content: "Zudem gelangst du durch einen klick zu der Anwesenheitsliste des Spieltags. Probiers aus!",
      action: () => {
        setAllowNavigation(false);
        if (route.pathname !== "/upcoming-events") {
          redirect("/Upcoming-Events", true);
        }
      }
    }
  ]

  const [searchParams] = useSearchParams();
  const redirect = useNavigate();
  const route = useLocation();
  const [step, setStep] = useState(0);
  const [allowNavigateion, setAllowNavigation] = useState(true);
  const setCurrentStep = (step) => {
    setAllowNavigation(true);
    setStep(step);
  };

  const handleClose = () => {
    const playerId = localStorage.getItem("playerId");

    // Sende den aktualisierten Onboarding-Status an die API
    api.put(`/Player/UpdateOnboardingStatus?playerId=${playerId}&onboardingStatus=${1}`)
      .then(() => {
        // Speichere den aktualisierten Onboarding-Status in LocalStorage
        localStorage.setItem("onboardingStatus", 1);
      })
      .catch(error => {
        console.error("Fehler beim Aktualisieren des Onboarding-Status:", error);
      });
  }

  return (
    <DarkModeProvider><PopupProvider><ErrorPopupProvider><InfoPopupProvider><AuthProvider><SuccessBarProvider>
    <TourProvider 
      steps={steps} 
      currentStep={step} 
      setCurrentStep={setCurrentStep}
      disableInteraction={allowNavigateion}
      showNavigation={allowNavigateion}
      styles={{
        popover: (base) => ({
          ...base,
          borderRadius: 10,
          backgroundColor: "var(--primary-color)",
          color: "var(--text-color)"
        }),
        dot: (base, { current }) => ({
          ...base,
          background: current ? "var(--accent-color)" : "var(--primary-color)"
        }),
        maskArea: (base) => ({ ...base, rx: 10 }),
        maskWrapper: (base) => ({ ...base, color: "rgba(0, 0, 0, 0.5)" }),
        badge: (base) => ({ ...base, backgroundColor: "var(--accent-color)" }),
        close: (base) => ({ ...base, right: 12, top: 12, color: "var(--text-color)" })
      }}
      className="onboarding"
      beforeClose={handleClose}
      padding={{
        popover: 20
      }}
    >
      <Wrapper />
    </TourProvider>
    </SuccessBarProvider></AuthProvider></InfoPopupProvider></ErrorPopupProvider></PopupProvider></DarkModeProvider>
  );
}

export default App;