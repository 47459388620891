import React, { useState, useEffect } from 'react';
import Navbar from '../nav/Navbar';
import NextEvent from '../home/NextEvent';
import RulesAndPricelist from '../home/RulesAndPricelist';
import { Link } from 'react-router-dom';
import '../styles/MainContainer.css'
import api from '../wrapper/API';
import { useTour } from '@reactour/tour';

function Home() {
  const [posts, setPosts] = useState([]);
  const {setIsOpen, setCurrentStep, isOpen} = useTour();

  useEffect(() => {
    api.get('/Event/GetFutureCombined')
      .then(response => {
        if (response.data.length !== 0) {
          const hasDoneOnboarding = localStorage.getItem("onboardingStatus") === '1' ? true : false || false;
          if (!hasDoneOnboarding) {
            setCurrentStep(0);
            setIsOpen(true);
          }
          setPosts(response.data);
        }
      })
      .catch((error) => {
        console.log(error.response.data)
      });

    if (posts.length === 0) {
      api.get('/Event/GetAllCombined')
      .then(response => {
        setPosts(response.data);
      })
      .catch((error) => {
        console.log(error.response.data)
      });
    }
  }, [posts.length, setCurrentStep, setIsOpen]);

  const post = posts[0];

  const openOnboarding = () => {
    setCurrentStep(0);
    setIsOpen(true);
  }

  const handleNextEventClick = () => {
    if (isOpen) {
      setCurrentStep(3);
    }
  }

  return (
    <React.Fragment>
      <Navbar homeSelected={true} extended={false} openOnboarding={openOnboarding} />
      <main className={"defaultMain"}>
        {post ?
          <Link to={`/event/${post.id}`} onClick={handleNextEventClick}>
            <NextEvent key={post.id} post={post} />
          </Link> : 
          <NextEvent post={post} />
        }
        <RulesAndPricelist />
      </main>
    </React.Fragment>
  );
}

export default Home;