import React, { useEffect, useRef, useState } from 'react';
import Navbar from '../nav/Navbar';
import '../player/PlayerList.css'
import PlayerList from '../player/PlayerList';
import api from '../wrapper/API';
import { useParams } from 'react-router-dom';
import CreateForm from '../form/CreateForm';
import CustomComboBox from '../input/CustomComboBox';
import StatusDot from '../status/StatusDot';
import { useSuccessBar } from '../context/SuccessBarContext';
import { useTour } from '@reactour/tour';

function Players() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    api
      .get(`/Player/GetAll`)
      .then((response) => {
        setPosts(response.data);
      })
      .catch(() => {
        
      });
  }, []);

  //Add Form

  const { eventId } = useParams();
  const [selectedPlayerStatus, setSelectedPlayerStatus] = useState(0);
  const {startSuccessBarLoading, startSuccessBarSuccess, closeSuccessBar} = useSuccessBar();
  const [formError, setFormError] = useState(null);

  const nachnameRef = useRef();
  const vornameRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();
  const passwordRef = useRef();

  const handleAddNewPlayerClick = (event) => {
    startSuccessBarLoading("Spieler wird erstellt")
    event.preventDefault();
    const requestBody = {
      eventId: eventId,
      lastname: nachnameRef.current.value,
      firstname: vornameRef.current.value,
      email: emailRef.current.value,
      phone: phoneRef.current.value,
      statusId: selectedPlayerStatus + 1,
      password: passwordRef.current ? passwordRef.current.value : ''
    }
    
    api.post('/Player/Create', requestBody)
      .then((response) => {
        startSuccessBarSuccess("Spieler erstellt");
        const updatedPosts = [...posts, response.data];
        setPosts(updatedPosts);

        setFormError(null);
        nachnameRef.current.value = '';
        vornameRef.current.value = '';
        emailRef.current.value = '';
        phoneRef.current.value = '';
        if (passwordRef.current) {
          passwordRef.current.value = '';
        }
        setSelectedPlayerStatus(0);
      })
      .catch(error => {
        closeSuccessBar();
        setFormError(error.response.data);
      })
  };

  const {setIsOpen, setCurrentStep} = useTour();

  const openOnboarding = () => {
    setCurrentStep(6);
    setIsOpen(true);
  }

  return (
    <React.Fragment>
      <Navbar usersSelected={true} extended={true} openOnboarding={openOnboarding} />
      <div className={"generalContainer"} >
        <PlayerList posts={posts} setPosts={setPosts} />
        <CreateForm onSubmit={handleAddNewPlayerClick} icon="person_add" error={formError}>
        <input required ref={nachnameRef} type="text" placeholder="Nachname" name="lastname" />
          <input required ref={vornameRef} type="text" placeholder="Vorname" name="firstname" />
          <input required ref={emailRef} type="text" placeholder="E-Mail" name="email" />
          <input required ref={phoneRef} type="text" placeholder="Telefonnummer" name="phone" />
          <CustomComboBox placeholder='Member' selected={selectedPlayerStatus} onStatusChange={setSelectedPlayerStatus}>
            <StatusDot type='member' statusId={1} />
            <StatusDot type='member' statusId={2} />
            <StatusDot type='member' statusId={3} />
          </CustomComboBox>
          {selectedPlayerStatus === 2 ?
            <React.Fragment>
              <input required ref={passwordRef} type="password" placeholder="Passwort" name="password" />
            </React.Fragment> : ''
          }
        </CreateForm>
      </div>
    </React.Fragment>
  );
}

export default Players;