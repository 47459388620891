import React, { useEffect, useRef, useState } from 'react'
import '../styles/MainContainer.css'
import ContextMenu from '../ContextMenu/ContextMenu';
import { useContextMenu } from '../context/ContextMenuContext';
import ContextMenuItem from '../ContextMenu/ContextMenuItem';
import './PlayerList.css';
import '../popup/EditPopup.css';
import ListHeader from '../list/ListHeader';
import ListItem from '../list/ListItem';
import StatusDot from '../status/StatusDot';
import CustomComboBox from '../input/CustomComboBox';
import api from '../wrapper/API';
import { useSuccessBar } from '../context/SuccessBarContext';
import { usePopup } from '../context/PopupContext';
import Popup from '../popup/Popup';

function PlayerList({posts, setPosts}) {
  const {startSuccessBarLoading, startSuccessBarSuccess, closeSuccessBar} = useSuccessBar();

  //Popup
  const {openPopup, closePopup, popupData} = usePopup();
  const [error, setError] = useState("");

  //Confirm Delete Popup
  const handleConfirmDelete = () => {
    startSuccessBarLoading("Spieler wird gelöscht");
    const foundPost = posts.find(post => post.id === contextMenuData.identifier);
    api.delete(`/Player/Delete?playerId=${foundPost.id}`)
      .then(() => {
        startSuccessBarSuccess("Spieler gelöscht");
        const updatedPosts = posts.filter(post => post.id !== foundPost.id);
        setPosts(updatedPosts);
      })
      .catch((error) => {
        closeSuccessBar();
        setError(error.response.data);
        openPopup("playererror", foundPost.id);
      })
    closePopup();
  };

  //Context menu
  const { contextMenuData } = useContextMenu();

  function callPlayer() {
    const foundPost = posts.find(post => post.id === contextMenuData.identifier);
    window.location.href = `tel:${foundPost.phone}`;
  }

  function emailPlayer() {
    const foundPost = posts.find(post => post.id === contextMenuData.identifier);
    window.location.href = `mailto:${foundPost.email}`;
  }

  function editPlayer() {
    const foundPost = posts.find(post => post.id === contextMenuData.identifier);
    setInfo(foundPost);
    setSelectedPlayerStatus(foundPost.statusId - 1);
    openPopup('editplayer', contextMenuData.identifier);
  }

  function deletePlayer() {
    openPopup("confirmdeleteplayer", contextMenuData.identifier);
  }

  //Info Popup
  const [info, setInfo] = useState({
    memo: ''
  });

  const showPlayerInfo = () => {
    const foundPost = posts.find(post => post.id === contextMenuData.identifier);
    setInfo(foundPost);
    openPopup('playerinfo', foundPost.id);
  };

  //Edit Popup
  const memoInputRef = useRef();
  const passwordInputRef = useRef();
  const [selectedPlayerStatus, setSelectedPlayerStatus] = useState(0);

  const handleEditPlayerConfirm = () => {
    startSuccessBarLoading("Änderungen werden übernommen")
    const foundPost = posts.find(post => post.id === popupData.identifier);
    const requestBody = {
      playerId: foundPost.id,
      statusId: selectedPlayerStatus + 1,
      memo: memoInputRef.current.value,
      password: passwordInputRef.current ? passwordInputRef.current.value : ''
    }

    api.put(`/Player/Edit?playerId=${requestBody.playerId}&memo=${requestBody.memo}&statusId=${requestBody.statusId}&password=${requestBody.password}`)
      .then((response) => {
        startSuccessBarSuccess("Änderungen übernommen");
        const updatedPosts = [...posts];
        const postIndex = updatedPosts.findIndex(post => post.id === foundPost.id);
        updatedPosts[postIndex].memo = response.data.memo;
        updatedPosts[postIndex].statusId = response.data.statusId;
        setPosts(updatedPosts);
      })
      .catch(error => {
        closeSuccessBar();
        setError(error.response.data);
        openPopup("playererror", requestBody.playerId);
      })
  };

  //width
  const [width, setWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <React.Fragment>
      <main className={"extendedMain"}>
        {
          width > 1000 ?
          <React.Fragment>
            <ListHeader gridTemplateColumns={'2fr 2fr 1fr 2fr 4fr'}>
              <p>Nachname</p>
              <p>Vorname</p>
              <p>Member</p>
              <p>Telefon</p>
              <p>E-Mail</p>
            </ListHeader>
            {posts.map((post, index) => (
              <ListItem 
                key={post.id}
                identifier={post.id}
                gridTemplateColumns={'2fr 2fr 1fr 2fr 4fr'}
                memo={post.memo}
                contextMenuIdentifier='player'
                index={index}
              >
                <p>{post.lastname}</p>
                <p>{post.firstname}</p>
                <StatusDot type='member' statusId={post.statusId} />
                <p>{post.phone}</p>
                <p>{post.email}</p>
              </ListItem>
            ))}
          </React.Fragment> :
          <React.Fragment>
            <ListHeader gridTemplateColumns={'2fr 2fr 1fr'}>
              <p>Nachname</p>
              <p>Vorname</p>
              <p>Member</p>
            </ListHeader>
            {posts.map((post, index) => (
              <ListItem 
                key={post.id}
                identifier={post.id}
                gridTemplateColumns={'2fr 2fr 1fr'}
                memo={post.memo}
                contextMenuIdentifier='player'
                index={index}
              >
                <p>{post.lastname}</p>
                <p>{post.firstname}</p>
                <StatusDot type='member' statusId={post.statusId} />
              </ListItem>
            ))}
          </React.Fragment>
        }
      </main>
      <ContextMenu type={'player'}>
        <ContextMenuItem label={'Anrufen'} icon={'call'} type={2} onClick={callPlayer} />
        <ContextMenuItem label={'E-Mail'} icon={'mail'} type={3} onClick={emailPlayer} />
        <ContextMenuItem label={'Info'} icon={'question_mark'} type={0} onClick={showPlayerInfo} />
        <ContextMenuItem label={'Bearbeiten'} icon={'edit'} type={0} onClick={editPlayer} />
        <ContextMenuItem label={'Entfernen'} icon={'delete_sweep'} type={1} onClick={deletePlayer} />
      </ContextMenu>
      <Popup name='confirmdeleteplayer' confirmText='Löschen' headerText='Aktion bestätigen' 
        type="confirmaction" onConfirm={handleConfirmDelete} withCancel
      >
        <p>Bist du sicher, dass du den Spieler dauerhaft löschen willst?</p>
      </Popup>
      <Popup name='playerinfo' confirmText={'Ok'} type="info">
        <h3>Memo:</h3>
        <div className={"memoOutput"}>
          {info.memo.split('\\n').map((zeile, index) => (
          index % 2 === 0 ? <h4 key={index}>{zeile}</h4> : <p key={index}>{zeile}</p>
          ))}
        </div>
      </Popup>
      <Popup name='editplayer' confirmText={'Übernehmen'} onConfirm={handleEditPlayerConfirm} withCancel type="info">
        <h3>Memo:</h3>
        <textarea ref={memoInputRef} className={"memoInput"} maxLength={200} placeholder='Memoeintrag hinzufügen'></textarea>
        <h3>Member:</h3>
        <CustomComboBox selected={selectedPlayerStatus} onStatusChange={setSelectedPlayerStatus}>
          <StatusDot type='member' statusId={1} />
          <StatusDot type='member' statusId={2} />
          <StatusDot type='member' statusId={3} />
          <StatusDot type='member' statusId={4} />
        </CustomComboBox>
        {info.statusId <= 2 && selectedPlayerStatus > 1 ? 
          <React.Fragment>
            <h3>Passwort:</h3>
            <input required ref={passwordInputRef} className={"textInput"} type="password" placeholder="Passwort" />
          </React.Fragment> : ''
        }
      </Popup>
      <Popup name='playererror' confirmText='Ok' headerText='Error' type="error">
        <p>{error}</p>
      </Popup>
    </React.Fragment>
  )
}

export default PlayerList