import React, { useEffect, useRef, useState } from 'react'
import PriceList from '../prices/PriceList';
import Navbar from '../nav/Navbar';
import '../prices/Price.css';
import api from '../wrapper/API';
import { useSuccessBar } from '../context/SuccessBarContext';
import CreateForm from '../form/CreateForm';

function Prices() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    api
      .get(`/Prices/GetAll`)
      .then((response) => {
        setPosts(response.data);
      })
      .catch(() => {
        
      });
  }, []);

  //Add Form
  const {startSuccessBarLoading, startSuccessBarSuccess, closeSuccessBar} = useSuccessBar();
  const [formError, setFormError] = useState(null);

  const bezeichnungRef = useRef();
  const preisRef = useRef();

  const handleAddNewPriceClick = (event) => {
    startSuccessBarLoading("Artikel wird erstellt")
    event.preventDefault();
    const requestBody = {
      article: bezeichnungRef.current.value,
      priceCents: preisRef.current.value === '' ? 0 : preisRef.current.value * 100,
    }
    
    api.post('/Prices/Create', requestBody)
      .then((response) => {
        startSuccessBarSuccess("Artikel erstellt");
        const updatedPosts = [...posts, response.data];
        setPosts(updatedPosts);
        setFormError(null);
        bezeichnungRef.current.value = '';
        preisRef.current.value = '';
      })
      .catch(error => {
        closeSuccessBar();
        setFormError(error.response.data);
      })
  };

  return (
    <React.Fragment>
      <Navbar extended={true}/>
      <div className={"generalContainer"} >
        <PriceList posts={posts} setPosts={setPosts} />
        <CreateForm onSubmit={handleAddNewPriceClick} icon="savings" error={formError}>
          <input required ref={bezeichnungRef} type="text" placeholder="Artikel" name="article" />
          <input required ref={preisRef} type="number" placeholder="Preis" name="price" />
        </CreateForm>
      </div>
    </React.Fragment>
  );
}

export default Prices