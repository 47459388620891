import React, { forwardRef, useRef, useImperativeHandle } from 'react'
import Icon from '../home/Icon';
import './Checkbox.css';

function Checkbox({ className, selectedState, onClick, identifier }, ref) {
  const iconRef = useRef();

  useImperativeHandle(ref, () => ({
    iconRef
  }));

  let icon = 'radio_button_unchecked';
  let checkboxColorStyle = '';

  if (selectedState === 1) {
    icon = 'done';
    checkboxColorStyle = "present";
  } else if (selectedState === 2) {
    icon = 'close';
    checkboxColorStyle = "notPresent";
  }

  const handleClick = () => {
    if (onClick) {
      onClick(identifier);
    }
  };

  return (
    <div ref={ref} onClick={handleClick}>
      <Icon ref={iconRef} className={`${className} checkbox ${checkboxColorStyle}`} icon={icon} />
    </div>
  )
}

export default forwardRef(Checkbox)