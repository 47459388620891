import React from 'react';
import './Navbar.css';

function Navicon({ icon, selected = false, className }) {
  return (
    selected ? (
      <span className={`material-symbols-rounded ${className} selected`}>{icon}</span>
    ) : (
      <span className={`material-symbols-rounded ${className} unselected`}>{icon}</span>
    )
  );
}

export default Navicon;