import React, { useCallback, useEffect, useState } from 'react';
import './ContextMenu.css';
import { useContextMenu } from '../context/ContextMenuContext';
import { AnimatePresence, motion } from 'framer-motion';

function ContextMenu({ type, children }) {
  const { isContextMenuOpen, contextMenuData, setContextMenuOffset, contextMenuPosition } = useContextMenu();
  const menuRef = useCallback((node) => {
    if (node) {
      setContextMenuOffset(node.offsetWidth, node.offsetHeight);
    }
  });

  useEffect(() => {
    setOpen(isContextMenuOpen);
  }, [isContextMenuOpen])

  const [isOpen, setOpen] = useState(false);

  const contextMenuVariants = {
    open: {
      scale: 1,
      top: contextMenuPosition.y,
      left: contextMenuPosition.x,
      transition: {
        type: "spring",
        bounce: 0,
        duration: 0.3,
      }
    },
    closed: {
      scale: 0,
      top: contextMenuPosition.y,
      left: contextMenuPosition.x,
      transition: {
        type: "spring",
        bounce: 0,
        duration: 0.3
      }
    }
  }

  return (
    <AnimatePresence>
      {
        isOpen && contextMenuData.type === type &&
        <motion.div 
          ref={menuRef} 
          className="contextMenu"
          variants={contextMenuVariants}
          initial="closed"
          animate="open"
          exit="closed"
        >
          <ul>
            {children}
          </ul>
        </motion.div>
      }
    </AnimatePresence>
  );
}

export default ContextMenu;