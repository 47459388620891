import React, { useEffect, useRef, useState } from 'react';
import Navbar from '../nav/Navbar';
import UpcomingEventsList from '../upcoming-events/UpcomingEventsList';
import '../upcoming-events/UpcomingEventsList.css';
import api from '../wrapper/API';
import { useSuccessBar } from '../context/SuccessBarContext';
import '../form/Form.css';
import CreateForm from '../form/CreateForm';
import Icon from '../home/Icon';
import { useTour } from '@reactour/tour';

function UpcomingEvents() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    api.get('/Event/GetAllCombined')
      .then(response => {
        setPosts(response.data);
      })
      .catch(() => {

      });
  }, []);

  //Add Form
  const {startSuccessBarLoading, startSuccessBarSuccess, closeSuccessBar} = useSuccessBar();
  const [formError, setFormError] = useState(null);

  const datumRef = useRef();

  const handleAddNewEventClick = (event) => {
    startSuccessBarLoading("Event wird erstellt")
    event.preventDefault();
    const requestBody = {
      date: datumRef.current.value
    }
    
    api.post('/Event/Create', requestBody)
      .then((response) => {
        startSuccessBarSuccess("Event erstellt");
        const updatedPosts = [...posts, response.data];
        setPosts(updatedPosts);
        setFormError(null);
        datumRef.current.value = '';
      })
      .catch((error) => {
        closeSuccessBar();
        setFormError(error.response.data);
      })
  };

  const {setIsOpen, setCurrentStep} = useTour();

  const openOnboarding = () => {
    setCurrentStep(9);
    setIsOpen(true);
  }

  return (
    <React.Fragment>
      <Navbar eventsSelected={true} extended={true} openOnboarding={openOnboarding} />
      <div className={"generalContainer"}>
        <UpcomingEventsList posts={posts} setPosts={setPosts} />
        <CreateForm onSubmit={handleAddNewEventClick} icon="edit_calendar" error={formError}>
        <div className={"dateinputContainer"}>
          <input required ref={datumRef} type="date" placeholder="Datum" name="date" />
          <Icon className={"datePickerIcon"} icon='edit_calendar' />
        </div>
        </CreateForm>
      </div>
    </React.Fragment>
  );
}

export default UpcomingEvents;