import React, { useEffect, useState } from 'react';
import './Popup.css';
import Icon from '../home/Icon';
import { AnimatePresence, motion } from 'framer-motion';
import { usePopup } from '../context/PopupContext';

function Popup({ children, name, onConfirm, confirmText = 'Ok', headerText = '', withCancel = false, dontCloseOnConfirm = false, type = 'info', cancelButtonType = "neutral" }) {
  const { isPopupOpen, popupData, closePopup } = usePopup();

  const handleOkClick = (event) => {
    event.preventDefault();
    if (onConfirm) {
      onConfirm();
    }
    if (!dontCloseOnConfirm) {
      closePopup();
    }
  };

  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    setOpen(isPopupOpen);
  }, [isPopupOpen])

  const popupVariants = {
    initial: {
      y: "-10vw",
      opacity: 0
    },
    visible: {
      y: 0,
      opacity: 1
    },
    exit: {
      y: "10vw",
      opacity: 0,
      transition: {
        type: "tween",
        duration: 0.15,
        ease: "easeIn"
      }
    }
  }

  const backgroundVariants = {
    initial: {
      opacity: 0
    },
    visible: {
      opacity: 1
    }
  }

  const getIcon = () => {
    switch (type) {
      case "error":
        return (
          <Icon className={"customPopupIcon negative"} icon={'warning'} />
        );
      case "warning":
        return (
          <Icon className={"customPopupIcon warning"} icon={'warning'} />
        );
      case "confirmaction":
        return (
          <Icon className={"customPopupIcon negative"} icon={'report'} />
        );
      default:
        return (
          <Icon className={"customPopupIcon positive"} icon={'info'} />
        );
    }
  }

  const [colorType, setColorType] = useState("positive");
  
  useEffect(() => {
    switch (type) {
      case "error":
        setColorType("negative");
        break;
      case "warning":
        setColorType("warning");
        break;
      case "confirmaction":
        setColorType("negative");
        break;
      default: 
        setColorType("positive");
        break;
    }
  }, [type, setColorType])

  return (
    <AnimatePresence>
      {
        isOpen && popupData.name === name &&
        <motion.div 
          className={"customPopupOverlay"}
          variants={backgroundVariants}
          initial="initial"
          animate="visible"
          exit="initial"
        >
          <motion.form 
            className={"customPopupContent"} 
            onSubmit={handleOkClick}
            variants={popupVariants}
            initial="initial"
            animate="visible"
            exit="exit"
          >
            {getIcon()}
            {headerText !== '' ? <h2>{headerText}</h2> : ''}
            <div className={"infoContainer"}>
              { children }
            </div>
            {withCancel ? <button type="button" className={`customPopupButton ${cancelButtonType}`} onClick={closePopup}>Abbrechen</button> : ''}
            <input type='submit' value={confirmText} className={`customPopupButton ${colorType}`} />
          </motion.form>
        </motion.div>
      }
    </AnimatePresence>
  );
}

export default Popup