import React, { useRef, useState } from 'react';
import './Navbar.css';
import Navicon from './Navicon';
import { Link } from 'react-router-dom';
import ContextMenu from '../ContextMenu/ContextMenu';
import { useContextMenu } from '../context/ContextMenuContext';
import { useDarkMode } from '../context/DarkModeContext';
import ContextMenuItem from '../ContextMenu/ContextMenuItem';
import '../popup/EditPopup.css';
import '../form/Form.css';
import { useAuth } from '../context/AuthContext';
import api from '../wrapper/API';
import { motion } from 'framer-motion';
import Popup from '../popup/Popup';
import { usePopup } from '../context/PopupContext';

function Navbar({ homeSelected = false, eventsSelected = false, usersSelected = false , extended = false, openOnboarding}) {
  const {setAuth} = useAuth();

  //Context Menu
  const { openContextMenu } = useContextMenu();
  const { isDarkMode, toggleDarkMode } = useDarkMode();

  const handleContextMenu = (event) => {
    openContextMenu(event, 0, 'settings');
  };

  const changePassword = () => {
    openPopup('editpassword', 0);
  };

  const showTutorial = () => {
    if (openOnboarding) {
      openOnboarding();
    }
  }

  const logout = () => {
    setAuth(false);
  };

  //Edit Popup
  const { openPopup, closePopup } = usePopup();
  const oldPasswordInputRef = useRef();
  const newPasswordInputRef = useRef();
  const newRepeatPasswordInputRef = useRef();
  const [error, setError] = useState(undefined);

  const handleChangePasswordConfirm = () => {
    if (newPasswordInputRef.current.value !== newRepeatPasswordInputRef.current.value) {
      setError('Die 2 Passwortfelder stimmen nicht überein.');
      return;
    }

    const requestBody = {
      oldPassword: oldPasswordInputRef.current.value,
      newPassword: newPasswordInputRef.current.value
    }

    api.put(`/Login/ChangePassword`, requestBody)
      .then(() => {
        setError(undefined);
        closePopup();
      })
      .catch(error => {
        setError(error.response.data);
      })
  };

  const settingsIconVariants = {
    initial: {
      rotate: 0
    },
    hover: {
      rotate: 100,
      cursor: "pointer"
    },
    click: {
      rotate: 150
    }
  }

  return (
    <React.Fragment>
      <nav className={extended ? "extendedNav" : "defaultNav"}>
        <section className={"navCenterSection"}>
          <Link to='/'>
            <div className={"navIconContainer"}>
              <Navicon icon='home' selected={homeSelected} className={"navIcon navHomeIcon"} />
            </div>
          </Link>
          <Link to='/upcoming-events'>
            <div className={"navIconContainer"}>
              <Navicon icon='event' selected={eventsSelected} className={"navIcon navEventIcon"} />
            </div>
          </Link>
          <Link to='/users'>
            <div className={"navIconContainer"}>
              <Navicon icon='person' selected={usersSelected} className={"navIcon navUsersIcon"} />
            </div>
          </Link>
        </section>
        <section className={"navRightSection"}>
          <div className={"settingsIconContainer"} onClick={handleContextMenu} onContextMenu={handleContextMenu} >
            <motion.span 
              className={`material-symbols-rounded settingsIcon unselected`}
              variants={settingsIconVariants}
              initial="initial"
              whileHover="hover"
              whileTap="click"
            >
              settings
            </motion.span>
          </div>
        </section>
      </nav>
      <ContextMenu type={'settings'}>
        {isDarkMode ? <ContextMenuItem label={'Light Mode'} icon={'light_mode'} type={0} onClick={toggleDarkMode} /> : <ContextMenuItem label={'Dark Mode'} icon={'dark_mode'} type={0} onClick={toggleDarkMode} />}
        <ContextMenuItem label={'Passwort ändern'} icon={'lock'} type={0} onClick={changePassword} />
        <ContextMenuItem label={'Tutorial anzeigen'} icon={'question_mark'} type={0} onClick={showTutorial} />
        <ContextMenuItem label={'Ausloggen'} icon={'logout'} type={1} onClick={logout} />
      </ContextMenu>
      <Popup name='editpassword' confirmText={'Übernehmen'} onConfirm={handleChangePasswordConfirm} withCancel dontCloseOnConfirm type="info">
        <h3>Passwort ändern:</h3>
        <input required type='password' ref={oldPasswordInputRef} className={"textInput"} placeholder='Altes Passwort' />
        <input required type='password' ref={newPasswordInputRef} className={"textInput"} placeholder='Neues Passwort' />
        <input required type='password' ref={newRepeatPasswordInputRef} className={"textInput"} placeholder='Neues Passwort wiederholen' />
        {error &&
          <p className={"newError"}>{error}</p>
        }
      </Popup>
    </React.Fragment>
  );
}

export default Navbar;