import React, { useState } from 'react';
import { motion } from 'framer-motion';
import Icon from '../home/Icon';
import './Analytics.css';

function Analytics({cards}) {
  const [selectedId, setSelectedId] = useState(null);

  const iconTextVariants = {
    hidden: {
      opacity: 0,
      transition: {
        delay: 0.3
      }
    },
    visible: {
      opacity: 1,
      transition: {
        delay: 0.3
      }
    }
  }

  return (
    <div className="analyticsContainer">
      {cards.map((card, i) => (
        <div key={i}>
          <motion.div
            className={selectedId === card ? 'analyticsCard open' : 'analyticsCard closed' }
            key={i}
            onClick={() => {
              if (selectedId === card) {
                setSelectedId(null);
              } else {
                setSelectedId(card);
              }
            }}
            layout
          >
            {selectedId === card ? (
              <div className={"extendedAnalyticsContainer"}>
                <p className={"analyticsTitle"}>{card.title}</p>
                {card.stats.map((stat, j) => (
                  <div className={"extendedAnalyticsSectionContainer"} key= {j}>
                    <motion.div 
                      className={"analysticsStatsDesc"}
                      initial={{
                        opacity: 0
                      }}
                      animate={{
                        opacity: 1,
                        transition: {
                          delay: 0.4
                        }
                      }}
                    >
                      <p>{(stat.negative ? "- " : "") + stat.title}</p>
                      <p className={"analyticsNumbers"}>{stat.value + stat.suffix + " / " + stat.max + stat.suffix}</p>
                    </motion.div>
                    <motion.svg 
                      className={"circularProgressBar"} 
                      width="140px" 
                      height="140px" 
                      xmlns="http://www.w3.org/2000/svg"
                      initial={{
                        opacity: 0
                      }}
                      animate={{
                        opacity: 1,
                        transition: {
                          delay: 0.4
                        }
                      }}
                    >
                      <motion.path 
                        className={"innerCircle"} 
                        id={"test"}
                        d={`M 70 30 a 1 1 0 0 ${stat.negative ? "0" : "1"} 0 80 a 1 1 0 0 ${stat.negative ? "0" : "1"} 0 -80`}
                        initial={{
                          pathLength: 0
                        }}
                        animate={{
                          pathLength: 1,
                          transition: {
                            duration: 0.7,
                            delay: 0.2
                          }
                        }}
                      />
                      <motion.path 
                        className={"progressCircle"} 
                        d={`M 70 30 a 1 1 0 0 ${stat.negative ? "0" : "1"} 0 80 a 1 1 0 0 ${stat.negative ? "0" : "1"} 0 -80`}
                        stroke={stat.negative ? "var(--negative-color)" : "var(--positive-color)"}
                        initial={{
                          pathLength: 0
                        }}
                        animate={{
                          pathLength: (stat.value / stat.max),
                          transition: {
                            delay: 0.4,
                            duration: 0.4
                          }
                        }}
                      />
                      <motion.text className={"percentageText"} x="70px" y="70px" textAnchor="middle" alignmentBaseline="middle">{(stat.negative ? "-" : "") + ((stat.value / stat.max) * 100).toFixed(0) + "%"}</motion.text>
                    </motion.svg>
                  </div>
                ))}
              </div>
            ) : (
              <div key={i}>
                <motion.p 
                  className={"analyticsTitle"}
                  variants={iconTextVariants}
                  initial="hidden"
                  animate="visible"
                >
                  {card.title}
                </motion.p>
                <motion.div 
                  className={"analyticsIconContainer"}
                  variants={iconTextVariants}
                  initial="hidden"
                  animate="visible"
                >
                  <Icon className={"analyticsIcon"} icon={card.icon} />
                </motion.div>
              </div>
            )}
          </motion.div>
        </div>
      ))}
    </div>
  )
}

export default Analytics