import React, { useEffect, useState } from 'react';
import Navbar from '../nav/Navbar';
import '../event/EventList.css';
import EventList from '../event/EventList';
import { useParams } from 'react-router-dom';
import api from '../wrapper/API';
import CreateForm from '../form/CreateForm';
import { useSuccessBar } from '../context/SuccessBarContext';
import CustomTogglebox from '../input/CustomTogglebox';
import { useTour } from '@reactour/tour';
import { motion } from 'framer-motion';

function Event() {
  document.documentElement.style.setProperty('--defaultMarginContainer', '10px')
  const [posts, setPosts] = useState([]);
  const { eventId } = useParams();

  useEffect(() => {
    api
      .get(`/EventPlayer/GetParticipants?eventId=${eventId}`)
      .then((response) => {
        setPosts(response.data);
      })
      .catch(() => {
        
      });
  }, [eventId]);

  //Add Form
  const {startSuccessBarLoading, startSuccessBarSuccess, closeSuccessBar} = useSuccessBar();
  const [formError, setFormError] = useState(null);

  const [nachname, setNachname] = useState("");
  const [vorname, setVorname] = useState("");
  const [useReferencePlayer, setUseReferencePlayer] = useState(false);
  const [referencePlayerName, setReferencePlayerName] = useState("");
  const [referencePlayerId, setReferencePlayerId] = useState(null);
  const [referencePlayerSelectionActive, setReferencePlayerSelectionActive] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [withEquipment, setWithEquipment] = useState(false);

  const handleAddNewPlayerClick = (event) => {
    event.preventDefault();
    if (useReferencePlayer && referencePlayerName === "") {
      setFormError("Es wurde kein Referenzspieler ausgewählt.");
      return;
    }

    startSuccessBarLoading("Spieler wird hinzugefügt");
    const requestBody = {
      eventId: eventId,
      lastname: nachname,
      firstname: vorname,
      email: email,
      phone: phone,
      equipment: withEquipment ? 1 : 0,
      referencePlayerId: referencePlayerId
    }
    
    api.post('/EventPlayer/AddParticipant', requestBody)
      .then((response) => {
        startSuccessBarSuccess("Spieler hinzugefügt");
        const updatedPosts = [...posts, response.data];
        setPosts(updatedPosts);
        setFormError(null);
        setNachname("");
        setVorname("");
        setEmail("");
        setPhone("");
        setWithEquipment("");
        setReferencePlayerId(null);
        setUseReferencePlayer(false);
        setReferencePlayerName("");
      })
      .catch(error => {
        closeSuccessBar();
        setFormError(error.response.data);
      })
  };

  const handleUseReferencePlayerChange = (event) => {
    setReferencePlayerName("");
    setReferencePlayerSelectionActive(false);
    setUseReferencePlayer(event.target.checked)
  };

  const handleListItemClick = (event, playerId, lastname, firstname) => {
    if (!useReferencePlayer || !referencePlayerSelectionActive) return;
    event.preventDefault();
    setReferencePlayerName(lastname + ", " + firstname);
    setReferencePlayerId(playerId);
    setReferencePlayerSelectionActive(false);
  };

  const useReferencePlayerVariants = {
    initial: {
      scale: 1
    },
    hover: {
      scale: 1.02,
      cursor: "pointer"
    },
    click: {
      scale: 0.98,
      transition: {
        duration: 0.001
      }
    }
  }

  const {setIsOpen, setCurrentStep} = useTour();

  const openOnboarding = () => {
    setCurrentStep(3);
    setIsOpen(true);
  }

  return (
    <React.Fragment>
      <Navbar eventsSelected={true} extended={true} openOnboarding={openOnboarding} />
      <div className={"generalContainer"}>
        <EventList posts={posts} setPosts={setPosts} eventId={eventId} onListItemClick={handleListItemClick} />
        <CreateForm onSubmit={handleAddNewPlayerClick} icon="person_add" error={formError}>
          <input value={nachname} onChange={e => setNachname(e.target.value)} required type="text" placeholder="Nachname" name="lastname" />
          <input value={vorname} onChange={e => setVorname(e.target.value)} required type="text" placeholder="Vorname" name="firstname" />
          {useReferencePlayer ? 
            <React.Fragment>
              <input value={referencePlayerName} onChange={e => setReferencePlayerName(e.target.value)} type="text" placeholder="Referenzspieler" name="referenceplayer" readOnly required />
              <motion.input 
                type="button" 
                value={referencePlayerSelectionActive ? "Abbrechen" : "Referenzspieler auswählen"} 
                name="choosereferenceplayer"
                onClick={() => setReferencePlayerSelectionActive(prev => !prev)}
                variants={useReferencePlayerVariants}
                initial="initial"
                whileHover="hover"
                whileTap="click"
              />
            </React.Fragment> : <React.Fragment>
              <input value={email} onChange={e => setEmail(e.target.value)} type="text" placeholder="E-Mail" name="email" />
              <input value={phone} onChange={e => setPhone(e.target.value)} type="text" placeholder="Telefonnummer" name="phone" />
            </React.Fragment>
          }
          <CustomTogglebox onChange={e => setWithEquipment(e.target.checked)} checked={withEquipment} placeholder={'Leihausrüstung'} />
          <CustomTogglebox onChange={handleUseReferencePlayerChange} checked={useReferencePlayer} placeholder={'Referenzspieler benutzen'} />
        </CreateForm>
      </div>
    </React.Fragment>
  );
}

export default Event;