import React, { useState, useContext, useCallback } from 'react';

// Erstelle den Kontext
const PopupContext = React.createContext();

// Hook zur Verwendung des Kontextes in Komponenten
export const usePopup = () => {
  return useContext(PopupContext);
};

// Kontextanbieter-Komponente
export const PopupProvider = ({ children }) => {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [popupData, setPopupData] = useState({ name: '', identifier: '' });

  // Funktion zum Öffnen des Kontextmenüs
  const openPopup = useCallback((name, identifier) => {
    setPopupData({ name, identifier });
    setPopupOpen(true);
  }, []);

  // Funktion zum Schließen des Kontextmenüs
  const closePopup = useCallback(() => {
    setPopupOpen(false);
  }, []);

  return (
    <PopupContext.Provider value={{ isPopupOpen, popupData, openPopup, closePopup }}>
      {children}
    </PopupContext.Provider>
  );
};