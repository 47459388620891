import React from 'react';
import './List.css';

function ListHeader({children, gridTemplateColumns = '1fr'}) {
  return (
    <div className={"listContainerHeader"} style={{gridTemplateColumns: `${gridTemplateColumns}`}}>
      {children}
    </div>
  );
}

export default ListHeader