import React from 'react';
import './NextEvent.css';
import Icon from './Icon';
import StatusDot from '../status/StatusDot';

export default function NextEvent({post}) {
  return (
    <div className={"nextEventContainer"}>
      {post ? 
        <React.Fragment>
          <div className={"nextEventDateContainer"}>
            <p className={"nextEventDate"}>{new Date(post.date).getDate()}</p>
            <p className={"nextEventMonth"}>{new Date(post.date).toLocaleString('default', { month: 'long' })}</p>
          </div>
          <div className={"nextEventInfoContainer"}>
            <div className={"nextEventInfoItem"}>
              <Icon className={"nextEventIcon"} icon='star' />
              <p className={"nextEventInfoText"}>Leihausrüstungen</p>
            </div>
            <p className={"nextEventInfoValue"}>{post.equipment}</p>
            <div className={"nextEventInfoItem"}>
              <Icon className={"nextEventIcon"} icon='person' />
              <p className={"nextEventInfoText"}>Teilnehmer</p>
            </div>
            <p className={"nextEventInfoValue"}>{post.participants}</p>
            <div className={"nextEventInfoItem"}>
              <Icon className={"nextEventIcon"} icon='leaderboard' />
              <p className={"nextEventInfoText"}>Status</p>
            </div>
            <StatusDot className={"nextEventInfoStatusValue"} type='event' statusId={post.statusId} style={{marginLeft: '60px'}} />
          </div>
        </React.Fragment>
         : null
      }
    </div>
  )
}
