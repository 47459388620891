import React, { useState, useContext } from 'react';

// Erstelle den Kontext
const AuthContext = React.createContext();

// Hook zur Verwendung des Kontextes in Komponenten
export const useAuth = () => {
  return useContext(AuthContext);
};

// Kontextanbieter-Komponente
export const AuthProvider = ({ children }) => {
  const [isAuth, setAuth] = useState(true);

  return (
    <AuthContext.Provider value={{ isAuth, setAuth }}>
      <div>
        {children}
      </div>
    </AuthContext.Provider>
  );
};