import React, { forwardRef } from 'react';
import './CustomTogglebox.css';
import Togglebox from './Togglebox';

function CustomTogglebox({placeholder, onChange, checked = false}, ref) {
  return (
    <div className={"customToggleboxContainer"}>
      <p>{placeholder}</p>
      <Togglebox ref={ref} onChange={onChange} checked={checked} />
    </div>
  )
}

export default forwardRef(CustomTogglebox)