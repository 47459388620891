import React, { useRef, useState } from 'react';
import './Login.css';
import { useNavigate } from 'react-router-dom';
import api from '../wrapper/API';
import { motion } from 'framer-motion';
import { usePopup } from '../context/PopupContext';
import Popup from '../popup/Popup';

function LoginForm() {
  const navigate = useNavigate();

  const emailTextFieldRef = useRef();
  const passwortTextFieldRef = useRef();

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (event) => { 
    setLoading(true);
    event.preventDefault();   
    const requestBody = {
      email: emailTextFieldRef.current.value,
      password: passwortTextFieldRef.current.value
    }
    
    api.post(`/Login/Login`, requestBody)
      .then((response) => {
        setLoading(false);
        setError(null);
        localStorage.setItem("onboardingStatus", response.data.onboardingStatus);
        localStorage.setItem("playerId", response.data.id);
        localStorage.setItem("statusId", response.data.statusId);
        if (response.data.hasChangedPassword === 0) {
          setOldPassword(requestBody.password);
          openPopup('editinitialpassword', 0);
        } else {
          navigate('/');
        }
      })
      .catch(error => {
        setLoading(false);
        setError(error.response.data);
      })
  };

  //Edit Password
  const [passwordError, setPasswordError] = useState(undefined);
  const [oldPassword, setOldPassword] = useState("");
  const { openPopup, closePopup } = usePopup();
  const newPasswordInputRef = useRef();
  const newRepeatPasswordInputRef = useRef();

  const handleChangePasswordConfirm = () => {
    if (newPasswordInputRef.current.value === oldPassword) {
      setPasswordError("Bitte wähle ein neues Passwort.");
      return;
    }
    if (newPasswordInputRef.current.value !== newRepeatPasswordInputRef.current.value) {
      setPasswordError('Die 2 Passwortfelder stimmen nicht überein.');
      return;
    }

    const requestBody = {
      oldPassword: oldPassword,
      newPassword: newPasswordInputRef.current.value
    }

    api.put(`/Login/ChangePassword`, requestBody)
      .then(() => {
        setPasswordError(undefined);
        closePopup();
        navigate('/');
      })
      .catch(error => {
        setPasswordError(error.response.data);
      })
  };

  return (
    <div className={"loginFormContainer"}>
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        <div className={"textField"}>
          <input ref={emailTextFieldRef} type='email' required name="email" />
          <span></span>
          <label>Email</label>
        </div>
        <div className={"textField"}>
          <input ref={passwortTextFieldRef} type='password' required name="password" />
          <span></span>
          <label>Passwort</label>
        </div>
        {error !== null && <p className={"loginError"}>{error}</p>}
        <div className={"forgotPassword"}>Passwort vergessen?</div>
        {loading ?  <div className="submitLoadingContainer">
          {[1, 2, 3].map((i) => {
            return (
              <motion.div
                key={i} 
                className="submitLoadingIcon"
                animate={{
                  scale: [1, 1.8, 1]
                }}
                transition={{
                  delay: i * 0.2,
                  duration: 0.6,
                  ease: "easeInOut",
                  repeat: Infinity,
                  repeatDelay: 0.5
                }}
              ></motion.div>
            )
          })}
        </div> : <input className="submitButton" type='submit' value='Login' />}
        <div className={"signUpLink"}>
          Noch kein Account?<a href='mailto:timorobin02@googlemail.com?subject=Account%20Anfrage&body=Hallo,%0Aich%20melde%20mich%20wegen%20einer%20Account%20Anfrage%20in%20eurem%20Orga%20System.%20Hier%20sind%20die%20benötigten%20Daten:%0A-%20Vorname:%20[Hier%20den%20Vornamen%20eintragen]%0A-%20Nachname:%20[Hier%20den%20Nachnamen%20eintragen]%0A-%Telefonnummer:%20[Hier%20die%20Telefonnummer%20eintragen]%0A'>
            Kontaktiere uns
          </a>
        </div>
      </form>
      <Popup type='editinitialpassword' confirmText={'Ändern'} onConfirm={handleChangePasswordConfirm} dontCloseOnConfirm>
        <h3>Bitte ändere dein Passwort;</h3>
        <input required type='password' ref={newPasswordInputRef} className={"textInput"} placeholder='Neues Passwort' />
        <input required type='password' ref={newRepeatPasswordInputRef} className={"textInput"} placeholder='Neues Passwort wiederholen' />
        {passwordError &&
          <p className={"newError"}>{passwordError}</p>
        }
      </Popup>
    </div>
  )
}

export default LoginForm