import React, { useRef, useState } from 'react';
import ListHeader from '../list/ListHeader';
import ListItem from '../list/ListItem';
import '../styles/MainContainer.css';
import { useContextMenu } from '../context/ContextMenuContext';
import ContextMenu from '../ContextMenu/ContextMenu';
import ContextMenuItem from '../ContextMenu/ContextMenuItem';
import '../popup/EditPopup.css';
import api from '../wrapper/API';
import { useSuccessBar } from '../context/SuccessBarContext';
import Popup from '../popup/Popup';
import { usePopup } from '../context/PopupContext';

function PriceList({posts, setPosts}) {
  const {startSuccessBarLoading, startSuccessBarSuccess, closeSuccessBar} = useSuccessBar();
  const [error, setError] = useState("");
  const {openPopup, closePopup} = usePopup();

  //Confirm Delete Popup
  const handleConfirmDelete = () => {
    startSuccessBarLoading("Artikel wird gelöscht");
    const foundPost = posts.find(post => post.id === contextMenuData.identifier);
    api.delete(`/Prices/Delete?id=${foundPost.id}`)
      .then(() => {
        startSuccessBarSuccess("Artikel gelöscht");
        const updatedPosts = posts.filter(post => post.id !== foundPost.id);
        setPosts(updatedPosts);
      })
      .catch((error) => {
        closeSuccessBar();
        setError(error.response.data);
        openPopup("priceerror", foundPost.id);
      })
    closePopup();
  };

  const handleOpenPopup = () => {
    openPopup("confirmdeleteprice");
  };

  //Context menu
  const { contextMenuData } = useContextMenu();

  function editPrice() {
    const foundPost = posts.find(post => post.id === contextMenuData.identifier);
    setInfo(x => x = foundPost);
    openPopup('editprice', contextMenuData.identifier);
  }

  function deletePrice() {
    handleOpenPopup();
  }

  //Edit Popup
  const [info, setInfo] = useState({
    id: 0,
    article: '',
    priceCents: 0
  });

  const priceInputRef = useRef();

  const handleEditPriceConfirm = () => {
    startSuccessBarLoading("Änderungen werden übernommen");
    const requestBody = {
      id: info.id,
      priceCents: priceInputRef.current.value
    }

    let requestLink = `/Prices/Edit?id=${requestBody.id}`;
    if (requestBody.priceCents !== '') requestLink += `&priceCents=${requestBody.priceCents * 100}`;

    api.put(requestLink)
      .then((response) => {
        startSuccessBarSuccess("Änderungen übernommen");
        const updatedPosts = [...posts];
        const postIndex = updatedPosts.findIndex(post => post.id === info.id);
        updatedPosts[postIndex].memo = response.data.memo;
        updatedPosts[postIndex].priceCents = response.data.priceCents;
        setPosts(updatedPosts);
      })
      .catch(error => {
        closeSuccessBar();
        setError(error.response.data);
        openPopup("priceerror", requestBody.id);
      })
    
  };

  const updatePriceCents = (event) => {
    const newValue = event.target.value;
    setInfo((prevInfo) => ({
      ...prevInfo,
      priceCents: newValue === '' ? 0 : newValue * 100
    }));
  };

  return (
    <React.Fragment>
      <main className={"extendedMain"}>
        <ListHeader gridTemplateColumns={'4fr 1fr'}>
          <p>Artikel</p>
          <p>Preis</p>
        </ListHeader>
        {posts.map((post, index) => (
          <ListItem 
            key={post.id}
            identifier={post.id}
            gridTemplateColumns={'4fr 1fr'}
            memo={''}
            contextMenuIdentifier='prices'
            index={index}
          >
            <p>{post.article}</p>
            <p>{(post.priceCents / 100).toFixed(2) + '€'}</p>
          </ListItem>
        ))}
      </main>
      <ContextMenu type={'prices'}>
        <ContextMenuItem label={'Bearbeiten'} icon={'edit'} type={0} onClick={editPrice} />
        <ContextMenuItem label={'Entfernen'} icon={'delete_sweep'} type={1} onClick={deletePrice} />
      </ContextMenu>
      <Popup name='confirmdeleteprice' confirmText='Entfernen' headerText='Aktion bestätigen' 
        type="confirmaction" onConfirm={handleConfirmDelete} withCancel
      >
        <p>Bist du sicher, dass du den Artikel dauerhaft löschen willt?</p>
      </Popup>
      <Popup name='editprice' confirmText={'Übernehmen'} onConfirm={handleEditPriceConfirm} withCancel type="info">
        <h3>Preis:</h3>
        <input required type='number' ref={priceInputRef} className={"priceInput"} min={0} max={1000} step={0.5} placeholder='Neuer Preis' value={info.priceCents / 100} onChange={updatePriceCents} />
      </Popup>
      <Popup name='priceerror' confirmText='Ok' headerText='Error' type="error">
        <p>{error}</p>
      </Popup>
    </React.Fragment>
  )
}

export default PriceList